.js-plotly-plot,
.plot-container,
.plot-container .svg-container,
.plot-container .main-svg {
    height: 100% !important;
    width: 100% !important;
}

.plot-container .main-svg, .plot-container .modebar-group {
    background-color: transparent !important;
}

.plot-container .modebar {
    left: var(--sigma-controls-margin) !important;
    top: var(--sigma-controls-margin) !important;
}

.plot-container .modebar-group:last-child {
    display: none !important;
}
