body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif
    ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace
    ;
}

details {
    background-color: #fafafa;
    border-radius: .24rem;
    margin: 2rem;
}

details a {
    color: #548eaa;
    text-decoration: none;
    word-break: break-all;
}

details a:hover {
    text-decoration: underline;
}

figure {
    text-align: center;
    margin: 2rem;
}

figcaption {
    color: #909090;
    font-size: .875rem;
    margin-top: .25rem;
}

img {
    max-height: 90%;
    max-width: 80%;
}

mark {
    background-color: transparent;
    font-weight: bolder;
}

pre {
    background-color: #fbfdff;
    border: 1px solid #e5e8ec;
    border-radius: .24rem;
    font-size: .875rem;
    margin: 2rem;
    padding: 1rem 2rem;
}

ol li {
    margin-bottom: 1rem;
}

summary {
    color: #548eaa;
    cursor: pointer;
    display: block;
    outline: 0;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-position: under;
    padding: 1rem 2rem;
    position: relative;
}

summary:before {
    border-color: transparent transparent transparent #548eaa;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    content: "";
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 75% 25%;
    transition: transform .1s linear;
}

summary+* {
    padding: 1rem 2rem;
}

table {
    display: inline-table;
    margin: 2rem;
    min-width: 80%;
}

table, td, th {
    border: 1px solid #d5dddf;
}

td, th {
    line-height: 2;
    padding: 1rem 2rem;
}

td:not([colspan]) {
    text-align: left;
    vertical-align: top;
}

#root {
    font-family: Montserrat, Arial, sans-serif;
}
